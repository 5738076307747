"use client";

import { MantineProvider } from "@mantine/core";
import { PropsWithChildren } from "react";

import { Toaster } from "@/components/ui/sonner";
import { getQueryClient } from "@/utils/get-query-client";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { QueryClientProvider } from "@tanstack/react-query";

const Provider = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <MantineProvider forceColorScheme="dark" defaultColorScheme="dark">
        <Notifications position="top-right" zIndex={10000} />
        <Toaster />

        {children}
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default Provider;
